import styled from "styled-components";

export const PageContainer = styled.div`
  margin: 20px;
`;

export const FormContainer = styled.section`
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  max-width: 1200px;
  width: 100%;
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

export const Column = styled.div`
  display: flex;
  column-gap: 15px;
  margin-top: 20px;

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

export const InputBox = styled.div`
  width: 100%;
  label {
    color: #333;
    font-weight: 500;
    font-size: 1rem;
    display: block;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    font-size: 1rem;
    color: #707070;
    border: 1px solid #ddd;
    border-radius: 6px;
    outline: none;
  

    &:focus {
      box-shadow: 0 1px 0 rgb(67,179,168);
    }
  }
`;


export const SubmitButton = styled.button`
  height: 55px;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #45b5aa;
  border-radius: 8px;

  &:hover {
    background: #2b978c;
  }
`;


export const GenerateButton = styled.button`
  border: none;
  margin-top: 10px;
  background: none;
  border-radius: 8px;
  color: #707070;
  border: 1px solid #ddd;
  border-radius: 6px;
  outline: none;

  &:hover {
    background: #2b978c;
    color: white;

  }
`