import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import { postRequest } from "../../utils/resquests";
import { add_resident } from "../../utils/API_urls";
import { Toaster, toast } from "sonner";
import Header from "../../components/Header";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { PageContainer, FormContainer, Form, Column, InputBox, SubmitButton, GenerateButton } from "./style";

export default function AddResident() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [companyNameRu, setCompanyNameRu] = useState("");
  const [pinfl, setPinfl] = useState("");
  const [oked, setOked] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Parolni ko'rsatish yoki yashirish holati
  const [email, setEmail] = useState("");
  const [typeCompany, setTypeCompany] = useState("");
  const [address, setAddress] = useState("");

  const generatePassword = () => {
    const length = 10;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    let newPassword = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }
    setPassword(newPassword);
    toast.success("Parol muvaffaqiyatli yaratildi!");
  };

  const handleSubmit = () => {
    setLoading(true);
    const req_body = {
      o: companyName,
      cn: companyName,
      name: companyNameRu,
      pinfl,
      tin: oked,
      password,
      email,
      typeCompany,
      alias: address,
    };

    postRequest(add_resident, req_body)
      .then(() => {
        toast.success("Resident added successfully!");
        setLoading(false);
        navigate("/admin");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          toast.error("Invalid data. Please check the inputs.");
        } else if (error.code === "ERR_NETWORK") {
          toast.error("Network error. Please try again.");
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Rezident qo'shish" subtitle="Rezident qo'shish" />
      </Box>
      <FormContainer>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Column>
            <InputBox>
              <label>Company Name</label>
              <input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Company Name"
                required
              />
            </InputBox>
            <InputBox>
              <label>Company Name Ru</label>
              <input
                type="text"
                value={companyNameRu}
                onChange={(e) => setCompanyNameRu(e.target.value)}
                placeholder="Company Name Ru"
                required
              />
            </InputBox>
          </Column>
          <Column>
            <InputBox>
              <label>PINFL</label>
              <input
                type="number"
                value={pinfl}
                onChange={(e) => setPinfl(e.target.value)}
                placeholder="Enter PINFL"
                required
              />
            </InputBox>
            <InputBox>
              <label>OKED</label>
              <input
                type="number"
                value={oked}
                onChange={(e) => setOked(e.target.value)}
                placeholder="Enter OKED"
                required
              />
            </InputBox>
          </Column>
          <Column>
            <InputBox style={{ position: "relative" }}>
              <label>Password</label>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "45px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              <GenerateButton type="button" onClick={generatePassword}>
                Generate Password
              </GenerateButton>
            </InputBox>
            <InputBox>
              <label>Email Address</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address"
                autoComplete="off"
                required
              />
            </InputBox>
          </Column>
          <Column>
            <InputBox>
              <label>Type Company</label>
              <input
                type="text"
                value={typeCompany}
                onChange={(e) => setTypeCompany(e.target.value)}
                placeholder="Enter type company"
                required
              />
            </InputBox>
            <InputBox>
              <label>Address</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter street address"
                required
              />
            </InputBox>
          </Column>
          <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        </Form>
      </FormContainer>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster key={123} richColors position="bottom-right" />
    </PageContainer>
  );
}
