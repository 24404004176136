import React, { useContext, useState } from "react";
import { postRequest } from "../../utils/resquests";
import { Toaster, toast } from "sonner";
import { invesment } from "../../utils/API_urls";
import { TextareaAutosize } from "@material-ui/core";
import { UserContext } from "../../context/UserContext";
import { delay } from "../../utils/helper";
import { useTranslation } from "react-i18next";

export default function PartThree({ setInvesment }) {
  const [volume_of_invest, setEmployees] = useState("");
  const [org_funds, setPartTime] = useState("");
  const [borrowed_funds, setCountforeign] = useState("");
  const [grants, setPerforming] = useState("");
  const [other, setFund] = useState("");
  const [saveButton, setSaveButton] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const handleSessionTimeOut = () => {
    sessionStorage.clear();
    setUser("")
    window.location.reload(false);
  };
  const savePartTree = async () => {
    postRequest(invesment, {
      volume_of_invest,
      org_funds,
      borrowed_funds,
      grants,
      other,
    })
      .then((response) => {
        if (response.status === 201) {
          setDisabled(true);
          setInvesment(response.data.report._id);
          toast.success("Muvaffaqiyatli saqlandi!");
          setSaveButton(false);
        }
        if (response?.status == 401) {
          toast.success("Xatolik");
          handleSessionTimeOut();
        }
      }).catch((error)=>{
        if (error?.response?.status == 401) {
          toast.success("Xatolik");
          handleSessionTimeOut();
        }
        toast.error("Serverda xatolik!");
      })
  };

  return (
    <>
      <div className="card-style mb-30">
        <h4 className="mb-10">
          III. {t('form_three')}
        </h4>
        <span className="right-position">({t('form_one_7')})</span>
        <table border="1">
          <thead>
            <tr className="custom-td">
              <td rowSpan="2" width="2%">
              {t('count')}
              </td>
              <td rowSpan="2" width="20%">
                {t('form_three_1')}
              </td>
              <td rowSpan="2" width="20%">
                {t('form_three_2')}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="custom-td">1</td>
              <td className="custom-tr custom-td">{t('form_three_3')}</td>
              <td></td>
            </tr>
            <tr>
              <td>1.1</td>
              <td className="custom-tr">{t('form_three_4')}</td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setEmployees(event.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="custom-td">2</td>
              <td className="custom-tr custom-td">{t('form_three_5')}</td>
              <td></td>
            </tr>
            <tr>
              <td>2.1</td>
              <td className="custom-tr">
                {t('form_three_6')}
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setPartTime(event.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>2.2</td>
              <td className="custom-tr">{t('form_three_7')}</td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setCountforeign(event.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>2.3</td>
              <td className="custom-tr">
                {t('form_three_8')}
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setPerforming(event.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>2.4</td>
              <td className="custom-tr">{t('form_three_9')}</td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setFund(event.target.value);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {saveButton ? (
          <div className="button-save">
            <button className="save-btn" onClick={savePartTree}>
              <i className="lni lni-save"></i>{t('button_save')}
            </button>
          </div>
        ) : (
          <div className="button-add">
            <button className="add-btn">{t('saved')}</button>
          </div>
        )}
      </div>
      <Toaster richColors position="bottom-right" />
    </>
  );
}
