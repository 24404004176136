import React, { useState, useEffect, useRef } from "react";
import { MuiFileInput } from "mui-file-input";
import Button from "@mui/material/Button";
import "./newstyle.css";
import { FormControl, TextField } from "@mui/material";
import { postRequest, uploadFile } from "../../utils/resquests";
import { Toaster, toast } from "sonner";
import { upload } from "../../utils/API_urls";
import SuccessMessage from "../SuccessMessage";
import ReCAPTCHA from "react-google-recaptcha";

export default function ApplyResidents() {
  const [statusResult, setStatusResult] = useState(true);
  const [value, setValue] = React.useState(null);

  //validate
  const [validate, setValidate] = useState(false);

  //captcha validation
  const [captVal, setCaptVal] = useState(null);

  // this states used for stroring files
  const [company, setCompany] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailValidation, setEmailValidation] = useState(true);
  const [requirements, setRequirements] = useState(null);
  const [application, setApplication] = useState(null);
  const [constituent_documents, setConstituentDocuments] = useState(null);
  const [description, setDescription] = useState(null);
  const [license, setLicense] = useState(null);
  const [copy_passport, setCopyPassport] = useState(null);
  const [project_description, setProjectDescription] = useState(null);
  const [candidate_application, setCandidateApplication] = useState(null);
  const [business_plan, setBusinessPlan] = useState(null);

  // this use state is used for createing body json from abouse state files
  const [body, setBody] = useState({});

  const recaptcha = useRef();

  // const handleFileInputChange = (fieldName, file) => {
  //   setFileInputs({ ...fileInputs, [fieldName]: file });
  // };

  // const handleTextChange = (event) => {
  //   setTextInput(event.target.value);
  // };


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeFile = (type, value) => {
    if (type == "requirements") {
      setRequirements(value);
    } else if (type == "application") {
      setApplication(value);
    } else if (type == "constituent_documents") {
      setConstituentDocuments(value);
    } else if (type == "description") {
      setDescription(value);
    } else if (type == "license") {
      setLicense(value);
    } else if (type == "copy_passport") {
      setCopyPassport(value);
    } else if (type == "project_description") {
      setProjectDescription(value);
    } else if (type == "candidate_application") {
      setCandidateApplication(value);
    } else if (type == "business_plan") {
      setBusinessPlan(value);
    }
  };
  const handleReport = (value, type) => {
    const formData = new FormData();
    formData.append("file", value);
    uploadFile(upload, formData)
      .then((response) => {
        // body.push({ type:response.data.link });
        body[type] = response.data.link;
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.message == "Please upload a file") {
          toast.error("Please upload a file");
        } else {
          toast.error("Serverda xatolik.");
        }
      });
  };
  const clearInputs = () => {
    setCompany(null);
    setEmail(null);
    setRequirements(null);
    setApplication(null);
    setConstituentDocuments(null);
    setDescription(null);
    setLicense(null);
    setCopyPassport(null);
    setProjectDescription(null);
    setCandidateApplication(null);
    setBusinessPlan(null);
  };
  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  useEffect(() => {
    localStorage.removeItem("savedEmail");
    setEmail("");
  }, []);

  const uploadFiles = async () => {
    body["company"] = company;
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      body["company"] = company;
    } else {
      setEmailValidation()
      return
    }
    body["email"] = email;
    handleReport(requirements, "requirements");
    handleReport(application, "application");
    handleReport(constituent_documents, "constituent_documents");
    handleReport(description, "description");
    handleReport(license, "license");
    handleReport(copy_passport, "copy_passport");
    handleReport(project_description, "project_description");
    handleReport(candidate_application, "candidate_application");
    handleReport(business_plan, "business_plan");
  };
  async function delay(ms) {
    // return await for better async stack trace support in case of errors.
    return await new Promise((resolve) => setTimeout(resolve, ms));
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidate(true);
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
      return;
    }
    if (captchaValue) {
      await Promise.all([uploadFiles()]);
    }
    // await setInterval(uploadFiles(), 1000);
    // await uploadFiles()
    await delay(2000);
    if (body.description) {
      await Promise.all([
        postRequest("/application_form/create", body)
          .then((response) => {
            toast.success("Muvaffaqiyatli!");
            // handleClose();
            clearInputs();
            setValidate(false);
            setStatusResult(false);
            // navigate({
            //   pathname: "/user",
            // });
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            toast.error("Serverda xatolik.");
          }),
      ]);
    }
  };

  return statusResult ? (
    <>
      <body id="animation-back" class="back-opacity">
        <section class="container">
          <header class="custom-font">ЗАЯВКА НА РЕЗИДЕНТСВО В IT-PARK ИННОВАЦИЙ В КИБЕРНЕТИКЕ</header>
          <header class="custom-font">В данной форме представлен полный перечень документов для подачи заявки на
            резидентство в IT-PARK ИННОВАЦИЙ В КИБЕРНЕТИКЕ *</header>
          <form action="#" class="form">
            <div class="input-box">
              <label>Название организации</label>
              <input type="text" placeholder="Название организации *" required />
            </div>
            <div class="input-box">
              <label>Электронная почта *</label>
              <input type="text" placeholder="Электронная почта *" required />
            </div>
            <div class="input-box">
              <label>Основные требования для получения статуса резидента (Файл для ознакомления №1)</label>
              <input class="file-upload" type="file" required />
            </div>
            <div class="input-box">
              <label>Заявление о регистрации в качестве резидента (Файл №2)</label>
              <input class="file-upload" type="file" required />
            </div>
            <button>Submit</button>
          </form>
        </section>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r121/three.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/vanta@latest/dist/vanta.net.min.js"></script>
        {/* <script>
          VANTA.NET({
            el: "#animation-back",
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          color: 0x3fa4ff,
          backgroundColor: 0x1b153c,
          points: 20.00,
          maxDistance: 23.00
        })
        </script> */}
      </body>
    </>
  ) : (
    <SuccessMessage />
  );
}
