import React, { useContext, useEffect, useState, useReducer } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  OutlinedInput,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Toaster, toast } from "sonner";
import PageSize from "../PageSize";
import { postRequest, deleteReports } from "../../utils/resquests";
import { company_list } from "../../utils/API_urls";
import { UserContext } from "../../context/UserContext";
import AccessDeny from "../AccessDeny";
import { DeleteButton } from "./style";

export default function TableExample() {
  const { user } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [compantList, setCompoundList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedID, setSelectedID] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [searchINN, setSearchINN] = useState(""); // Qidiruv maydoni
  const [a, forceUpdate] = useReducer((x) => x + 1, 0);
  const [pinflSearch, setPinflSearch] = useState("");


  // Dialog ochish va yopish funksiyalari
  const handleDialogOpen = (id) => {
    setSelectedID(id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handlePasswordDialogOpen = (id) => {
    setSelectedID(id);
    setPasswordDialogOpen(true);
  };

  const handlePasswordDialogClose = () => {
    setPasswordDialogOpen(false);
    setNewPassword("");
  };

  // Parolni ko'rinadigan/qorong'i qilish funksiyasi
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Parolni saqlash funksiyasi
  const handlePasswordUpdate = () => {
    postRequest("/company_form/npassword", {
      adminId: user.id,
      selectedID: selectedID,
      newPassword: newPassword,
    })
      .then(() => {
        toast.success("Parol muvaffaqiyatli yangilandi!");
        handlePasswordDialogClose();
      })
      .catch((error) => {
        toast.error("Xatolik yuz berdi, iltimos qayta urinib ko'ring.");
      });
  };

  const handleDelete = () => {
    if (selectedID) {
      deleteReports(`company_form/delete?id=${selectedID}`)
        .then(() => {
          setCompoundList((prev) => prev.filter((item) => item._id !== selectedID));
          setDialogOpen(false);
          setSelectedID(null);
          toast.success("Muvaffaqiyatli o'chrildi!");
          forceUpdate();
        })
        .catch((error) => {
          toast.error("Server xatolik", error);
          setDialogOpen(false);
          forceUpdate();
        });
    }
  };

  useEffect(() => {
    if (user?.role.filter((e) => e === "admin" || e === 'admin_view'|| e === 'crm').length > 0) {
      postRequest(company_list, {
        pageNumber: page,
        pageSize: pageSize,
        pinfl: pinflSearch == "" ? null : pinflSearch,
      })
        .then((response) => {
          setPageCount(response.data.page);
          setCompoundList(response.data.list_of_companies);
          setFilteredList(response.data.list_of_companies);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [page, pageSize, pinflSearch]);


  const handleSearch = (event) => {
    setPinflSearch(event.target.value);
  };

  if (!(user?.role.filter((e) => e === "admin" || e === 'admin_view'|| e === 'crm').length > 0)) {
    return <AccessDeny />;
  }

  return (
    <TableContainer component={Paper}>
      <Box sx={{ padding: 2 }}>
        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            type="text"
            value={pinflSearch}
            onChange={handleSearch}
            placeholder="INN bo'yicha qidiruv"
          />
        </FormControl>
      </Box>

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tashkilot nomi</TableCell>
            <TableCell align="right">INN</TableCell>
            <TableCell align="right">TIN</TableCell>
            <TableCell align="right">Yaratilgan sana</TableCell>
            <TableCell align="right">Oxirgi yangilanish</TableCell>
            <TableCell align="right">O'chirish</TableCell>
            <TableCell align="right">Parol yangilash</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredList.map((row) => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">{row.organization_name}</TableCell>
              <TableCell align="right">{row.pinfl}</TableCell>
              <TableCell align="right">{row.tin}</TableCell>
              <TableCell align="right">{row.created_at}</TableCell>
              <TableCell align="right">{row.updated_at}</TableCell>
              <TableCell align="right">
                <DeleteButton>
                  <p onClick={() => handleDialogOpen(row._id)}>
                    <i className="ti-trash"></i>
                  </p>
                </DeleteButton>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="outlined"
                  onClick={() => handlePasswordDialogOpen(row._id)}
                >
                  Yangilash
                </Button>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={7}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <PageSize
                  setPageSize={(val) => {
                    setPageSize(val);
                  }}
                />
                <Pagination
                  count={pageCount}
                  color="primary"
                  page={page}
                  onChange={(_, value) => setPage(value)}
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <DialogContentText>Kompaniyani o'chirishni tasdiqlaysizmi?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Yo'q</Button>
          <Button onClick={handleDelete} color="primary">Ha</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={passwordDialogOpen} onClose={handlePasswordDialogClose}>
        <DialogContent>
          <DialogContentText>Yangi parolni kiriting:</DialogContentText>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordDialogClose} color="primary">Bekor qilish</Button>
          <Button onClick={handlePasswordUpdate} color="primary">Saqlash</Button>
        </DialogActions>
      </Dialog>

      <Toaster key={123} richColors position="bottom-right" />
    </TableContainer>
  );
}
