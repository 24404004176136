import React, { useEffect, useContext } from "react";
import { postRequest } from "../../utils/resquests";
import { residental_payroll } from "../../utils/API_urls";
import { Toaster, toast } from "sonner";
import { useState } from "react";
import { TextareaAutosize } from "@material-ui/core";
import { UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

export default function PartFour({
  setResidentalPayroll,
  validateResidentalPayroll,
}) {
  const [employees, setEmployees] = useState({ Unit: "", period: "" });
  const [part_time, setPartTime] = useState({ Unit: "", period: "" });
  const [countforeign, setCountforeign] = useState({ Unit: "", period: "" });
  const [performing, setPerforming] = useState({ Unit: "", period: "" });
  const [fund, setFund] = useState({ Unit: "", period: "" });
  const [saveButton, setSaveButton] = useState(true);
  const [error, setState] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const handleSessionTimeOut = () => {
    sessionStorage.clear();
    setUser("");
    window.location.reload(false);
  };
  useEffect(() => {
    setState(validateResidentalPayroll);
  }, [validateResidentalPayroll]);
  const savePartTree = () => {
    let checkValue = !(
      employees.Unit != "" &&
      fund.Unit != "" &&
      employees.period != "" &&
      fund.period != ""
    );
    if (checkValue) {
      toast.error("Serverda xatolik!");
      setState(false);
      return;
    } else {
      setState(true);
    }
    postRequest(residental_payroll, {
      employees,
      part_time,
      countforeign,
      performing,
      fund,
    })
      .then((response) => {
        if (response.status === 201) {
          setResidentalPayroll(response.data.report._id);
          setDisabled(true);
          setSaveButton(false);
          toast.success("Muvaffaqiyatli saqlandi!");
        }
        if (response?.status == 401) {
          toast.success("Xatolik");
          handleSessionTimeOut();
        }
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          toast.success("Xatolik");
          handleSessionTimeOut();
        }
        toast.error("Serverda xatolik!");
      });
  };

  return (
    <>
      <div className="card-style mb-30">
        <h4 className="mb-10">
          IV. {t('form_four')}<span className="stars-red">*</span>
        </h4>
        <span className="right-position">({t('form_one_7')})</span>
        <table border="1">
          <thead>
            <tr className="custom-td">
              <td rowSpan="2" width="2%">
                {t('count')}
              </td>
              <td rowSpan="2" width="20%">
                {t('form_three_1')}
              </td>
              <td rowSpan="2" width="10%">
                {t('form_four_1')}
              </td>
              <td rowSpan="2" width="10%">
                {t('form_three_2')}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="custom-td">1</td>
              <td className="custom-tr">
                {t('form_four_3')} <span className="stars-red">*</span>
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setEmployees((prev) => {
                      prev.Unit = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="number"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setEmployees((prev) => {
                      prev.period = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="custom-td">2</td>
              <td className="custom-tr">
                {t('form_four_4')}
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setPartTime((prev) => {
                      prev.Unit = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="number"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setPartTime((prev) => {
                      prev.period = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="custom-td">3</td>
              <td className="custom-tr">
                {t('form_four_5')}
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setCountforeign((prev) => {
                      prev.Unit = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="number"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setCountforeign((prev) => {
                      prev.period = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="custom-td">4</td>
              <td className="custom-tr">
                {t('form_four_6')}
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setPerforming((prev) => {
                      prev.Unit = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="number"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setPerforming((prev) => {
                      prev.period = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="custom-td">5</td>
              <td className="custom-tr">
                {t('form_four_7')} <span className="stars-red">*</span>
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="text"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setFund((prev) => {
                      prev.Unit = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
              <td>
                <TextareaAutosize
                  className="text-area-custom"
                  type="number"
                  name="column1"
                  id="column1"
                  disabled={disabled}
                  onChange={(event) => {
                    setFund((prev) => {
                      prev.period = event.target.value;
                      return prev;
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {error ? (
          <></>
        ) : (
          <div class="warn-file-second">
            {t('error_text')}
          </div>
        )}
        {saveButton ? (
          <div className="button-save">
            <button className="save-btn" onClick={savePartTree}>
              <i className="lni lni-save"></i> {t('button_save')}
            </button>
          </div>
        ) : (
          <div className="button-add">
            <button className="add-btn">{t('saved')}</button>
          </div>
        )}
      </div>
      <Toaster richColors position="bottom-right" />
    </>
  );
}
