import styled from "styled-components";

export const InputSearch = styled.div`

  label {
    color: #333;
    font-weight: 500;
    font-size: 1rem;
    display: block;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    font-size: 1rem;
    color: #707070;
    border: 1px solid #ddd;
    border-radius: 6px;
    outline: none;
  

    &:focus {
      box-shadow: 0 1px 0 rgb(67,179,168);
    }
  }
`;

export const DeleteButton = styled.div`
  p {
    text-align: center;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    color: red;
    font-size: 20px;
  }
`